import * as React from 'react';
import ImportProgress from "../components/ImportProgress";

export interface BatchFile {
    format:string,
    cases: number,
    date: string,
    status: string,
    statusType: string,
    current: number,
    total: number,
    errors: string,
    hasTarmedVolumes: boolean
}

interface Props {
    locale: string,
    batchFileId: number,
    userName: number,
    batchFile: BatchFile,
    page: string
}

class ImportProgressPage extends React.Component<Props> {
    render() {
        return (<ImportProgress {...this.props} />)
    }
}

export default ImportProgressPage
